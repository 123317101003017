import { useAuthStore } from '~/store/auth'
import { initAuth } from '~/utils/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip middleware on server
  if (import.meta.server) return

  const store = useAuthStore()

  if (store.user === null) {
    await initAuth(store)
  }
})
